import React, { useEffect, useRef, useState } from "react";
import Layout from "../Layout/Content";
import axios from "axios";
import Chart from "react-apexcharts";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "./styles.css";
import { ColorType, createChart } from "lightweight-charts";
import ChartComponent from "../ChartComponent/Content";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../LoaderPageSection/Loading";
const Content = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { LoginReducer } = useSelector((res) => res);
  const [coinList, setCoinList] = useState([]);
  const [coinName, setCoinName] = useState("");
  const [isdropdown, setIsdropdown] = useState(false);
  const [coinData, setCoinData] = useState([]);
  const [buyHistory, setBuyHistory] = useState([]);
  const [sellHistory, setSellHistory] = useState([]);
  const [chartCandleData, setChartCandleData] = useState([]);
  const [balanceData, setBalanceData] = useState([]);
  const [sellBalanceData, setSellBalanceData] = useState([]);
  const [tradeHistoryData, setTradeHistoryData] = useState([]);
  const [selectedPercentage, setSelectedPercentage] = useState(0);
  const [selectedSellPercentage, setSelectedSellPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("1");
  const [buyAsset, setBuyAsset] = useState({
    usdtAmount: null,
  });
  const [sellAsset, setSellAsset] = useState({
    sellAmount: null,
    coinName: "",
    targetCoinName: "",
    coinId: "",
    type: "1",
  });

  const localCoinId = localStorage.getItem("coinId");
  const handlePercentageClick = (percentage, balance) => {
    const calculatedAmount = (percentage / 100) * balance;
    setSelectedPercentage(percentage);
    setBuyAsset({
      ...buyAsset,
      usdtAmount: calculatedAmount,
    });
  };

  const handleSellPercentageClick = (percentage, balance) => {
    const calculatedAmount = (percentage / 100) * balance;
    setSelectedSellPercentage(percentage);
    setSellAsset({
      ...sellAsset,
      sellAmount: calculatedAmount,
    });
  };
  const handleSellCoin = async () => {
    setLoading(true);
    const sellCoinData = {
      ...sellAsset,
      coinName: coinData?.symbol?.toUpperCase(),
      targetCoinName: "USDT",
      coinId: coinData?.id,
      type: "1",
    };
    try {
      const resp = await axios.post(`/api/v1/user-sellAssets`, sellCoinData);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    window.location.reload(false);
    setLoading(false);
  };

  const handleBuyCoin = async () => {
    setLoading(true);
    const data = {
      ...buyAsset,
      coinName: "USDT",
      targetCoinName: coinData?.symbol?.toUpperCase(),
      coinId: coinData?.id,
      type: "0",
    };

    try {
      const resp = await axios.post(`/api/v1/user-buyAssets`, data);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    window.location.reload(false);
    setLoading(false);
  };

  const handleOnChange = (key, value) => {
    setBuyAsset((prevBuyAsset) => ({
      ...prevBuyAsset,
      [key]: value,
    }));
  };

  const handleOnChangeSell = (key, value) => {
    setSellAsset((prevSellAsset) => ({
      ...prevSellAsset,
      [key]: value,
    }));
  };

  const handleCoinList = async () => {
    const coinId = localCoinId === null ? 0 : localCoinId;
    try {
      const resp = await axios.get(`/api/coin/getAllCoins`);
      if (resp.status === 200) {
        setCoinList(resp?.data?.Coins[0]?.coins);
        setCoinData(resp?.data?.Coins[0]?.coins[coinId]);
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    // setLoading(false);
  };

  const handleOhlc = async () => {
    // const coiniD = coinData.id !== undefined ? coinData.id : "bitcoin";
    if (coinData.id !== undefined) {
      if (coinData.id === "hypercoin" || coinData.id === "hyperxalpha" || coinData.id==="sioc" || coinData.id==="sbdc") {
        localStorage.setItem("coinName", coinData?.name);
        const adminCoinTime = localStorage.getItem("adminCoinTime");
        const CoinTime = adminCoinTime === null ? 1 : adminCoinTime;
        setLoading(true);
        try {
          const resp = await axios.get(
            `/api/candle/getCandelHistory?coinName=${coinData.id}`
          );
          if (resp.status === 200) {
            if (resp?.data?.status === true) {
              setChartCandleData(resp?.data?.getAllCandelHistoryData);
            } else {
              toast.error("Error", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } else {
            toast.error("Error", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            toast.error(err_401);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            toast.error(err_500);
          } else {
            console.log("Error in Register", error);
            toast.error("something went wrong");
          }
        }
        setLoading(false);
      } else {
        setLoading(true);

        try {
          const coinTime = localStorage.getItem("coinTime");
          const CoinTime = coinTime === null ? "1" : coinTime;
          const { data } = await axios.get(
            `https://api.coingecko.com/api/v3/coins/${coinData.id}/ohlc?vs_currency=usd&days=${CoinTime}`
          );
          localStorage.setItem("coinName", coinData?.name);
          const convertedData = data.map((item) => {
            const [time, open, high, low, close] = item;
            return { time, open, high, low, close };
          });

          setChartCandleData(convertedData);
        } catch (error) {
          toast.error("Error in candle");
        }
        setLoading(false);
      }
    }
  };

  const handleCallBalance = async () => {
    try {
      const resp = await axios.get(`/api/v1/user-wallet-assets`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setBalanceData(resp?.data?.data);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  const handleSellCallBalance = async () => {
    const sellCoinName = coinData?.symbol?.toUpperCase();
    try {
      const resp = await axios.get(`/api/v1/user-wallet-assets`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          const resData = resp?.data?.data;
          const filterSellData = resData.filter(
            (value, key) => value?.symbol === sellCoinName
          );
          setSellBalanceData(filterSellData);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  // const handleBuyHistory = async (coinName) => {
  //   try {
  //     const resp = await axios.get(
  //       `/api/v1/buyFilter-coinTranHistory?coin=${coinName}&type=0`
  //     );
  //     if (resp.status === 200) {
  //       if (resp?.data?.status === true) {
  //         const resData = resp?.data?.data;
  //         setBuyHistory(resData);
  //       } else {
  //         toast.error("Error", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     } else {
  //       toast.error("Error", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   } catch (error) {
  //     if (error.response.status === 400) {
  //       var err_400 = error?.response?.data?.message;
  //       toast.error(err_400);
  //     } else if (error.response.status === 401) {
  //       const err_401 = error?.response?.data?.message;
  //       toast.error(err_401);
  //     } else if (error.response.status === 500) {
  //       const err_500 = error?.response?.data?.message;
  //       toast.error(err_500);
  //     } else {
  //       toast.error("something went wrong");
  //     }
  //   }
  // };

  // const handleSellHistory = async (coinName) => {
  //   try {
  //     const resp = await axios.get(
  //       `/api/v1/sellFilter-coinTranHistory?coin=${coinName}&type=1`
  //     );
  //     if (resp.status === 200) {
  //       if (resp?.data?.status === true) {
  //         const resData = resp?.data?.data;
  //         console.log("SellresData", resData);
  //         setSellHistory(resData);
  //       } else {
  //         toast.error("Error", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     } else {
  //       toast.error("Error", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   } catch (error) {
  //     if (error.response.status === 400) {
  //       var err_400 = error?.response?.data?.message;
  //       toast.error(err_400);
  //     } else if (error.response.status === 401) {
  //       const err_401 = error?.response?.data?.message;
  //       toast.error(err_401);
  //     } else if (error.response.status === 500) {
  //       const err_500 = error?.response?.data?.message;
  //       toast.error(err_500);
  //     } else {
  //       toast.error("something went wrong");
  //     }
  //   }
  // };
  // const handleTradeHistory = async () => {
  //   setLoading(true);
  //   try {
  //     const resp = await axios.get(`/api/v1/user-transactionHistory`);
  //     if (resp.status === 200) {
  //       if (resp?.data?.status === true) {
  //         setTradeHistoryData(resp?.data?.data);
  //       } else {
  //         toast.error("Error", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     } else {
  //       toast.error("Error", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
  //   } catch (error) {
  //     if (error.response.status === 400) {
  //       var err_400 = error?.response?.data?.message;
  //       toast.error(err_400);
  //     } else if (error.response.status === 401) {
  //       const err_401 = error?.response?.data?.message;
  //       toast.error(err_401);
  //     } else if (error.response.status === 500) {
  //       const err_500 = error?.response?.data?.message;
  //       toast.error(err_500);
  //     } else {
  //       console.log("Error in Register", error);
  //       toast.error("something went wrong");
  //     }
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    handleCoinList();
    handleOhlc();
  }, []);
  useEffect(() => {
    handleOhlc();
  }, [coinData, selectedValue]);

  useEffect(() => {
    if (LoginReducer?.isLogged) {
      handleCallBalance();
    }
  }, [LoginReducer]);

  useEffect(() => {
    if (LoginReducer?.isLogged) {
      if (coinData?.symbol?.toUpperCase() !== undefined) {
        handleSellCallBalance(coinData?.symbol?.toUpperCase());
      }
    }
  }, [LoginReducer, coinData, selectedValue]);

  // useEffect(() => {
  //   if (LoginReducer?.isLogged) {
  //     handleTradeHistory();
  //   }
  // }, [LoginReducer]);

  // useEffect(() => {
  //   if (LoginReducer?.isLogged) {
  //     if (coinData?.symbol?.toUpperCase() !== undefined) {
  //       const coinName = coinData?.symbol?.toUpperCase();
  //       handleSellHistory(coinName);
  //       handleBuyHistory(coinName);
  //     } else {
  //       handleSellHistory("BTC");
  //       handleBuyHistory("BTC");
  //     }
  //   }
  // }, [LoginReducer, coinData]);

  const handletrade = async (coinName) => {
    const filterKey = `${coinName}/USDT`;
    const filterKey2 = `USDT/${coinName}`;
    setLoading(true);
    try {
      const resp = await axios.get(`/api/v1/user-tradeHistory`);
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          const allData = resp?.data?.data;
          const filterTradeData = allData?.filter(
            (value) =>
              value.cryptoPair === filterKey || value.cryptoPair === filterKey2
          );
          setTradeHistoryData(filterTradeData);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (LoginReducer?.isLogged) {
      if (coinData?.symbol?.toUpperCase() !== undefined) {
        const coinName = coinData?.symbol?.toUpperCase();
        handletrade(coinName);
      } else {
        handletrade("BTC");
      }
    }
  }, [LoginReducer, coinData]);

  return (
    <Layout>
      {loading && <Loading loading={loading} />}
      <div className="main">
        <div className="row">
          <div className="col-12">
            <div className="bottom-btn-box">
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-2 col-6">
                  <button
                    className="btn  botton-buy-btn shadow"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Buy
                  </button>
                </div>
                <div className="col-md-6 col-lg-2 col-6">
                  <button
                    className="btn botton-sell-btn shadow"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalm"
                  >
                    sell
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 overflow-div">
              <div className="main-cus">
                <div className="market-nav">
                  <p>coin</p>
                  <p>{coinData?.symbol?.toUpperCase()}</p>
                </div>
                <div className="market-nav">
                  <p>Price</p>
                  <p>${coinData?.current_price}</p>
                </div>
                <div className="market-nav">
                  <p>change</p>
                  <p>{coinData?.price_change_percentage_24h}%</p>
                </div>
                {/* <div className="market-nav">
                  <p>24H-High</p>
                  <p>{coinData?.high_24h}</p>
                </div>
                <div className="market-nav">
                  <p>24H-Low</p>
                  <p>{coinData?.low_24h}</p>
                </div> */}
                <div className="market-nav">
                  <p>24H-Volume</p>
                  <p>{coinData?.total_volume}</p>
                </div>
              </div>
            </div>
          </div>
          {/* coin drop down menu */}
          {isdropdown && (
            <div className="sidebarmarketplace">
              {coinList &&
                coinList.map((value, key) => {
                  return (
                    <div
                      className="currency-box"
                      onClick={() => (
                        setCoinData(value),
                        setIsdropdown(false),
                        localStorage.setItem("coinId", key)
                      )}
                    >
                      <img src={value?.image} alt="" />
                      <span> {`${value.symbol.toUpperCase()}/USDT`}</span>
                    </div>
                  );
                })}
            </div>
          )}

          {/* coin drop down button */}

          <div className="row">
            <div className="col-12 col-md-6 my-2">
              <div className="sidehistorybtn-box">
                <button
                  className="btn currency-sl-btn mx-2 shadow-none dropdown-toggle"
                  onClick={() => setIsdropdown(!isdropdown)}
                >
                  <img src={coinData.image} alt="" className="mx-2" />
                  {`${coinData?.symbol?.toUpperCase()}/USDT`}
                </button>
                <span
                  className="btn refreshbtn"
                  onClick={() => handleCoinList()}
                >
                  <i class="fa-solid fa-arrows-rotate"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            {/* chart */}
            <div className="col-12 col-md-12">
              <div>
                <div className="chart-box">
                  <ChartComponent
                    {...props}
                    data={chartCandleData}
                    setSelectedValue={setSelectedValue}
                  ></ChartComponent>
                </div>
              </div>

              {/* <!--Buy Modal --> */}

              <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg bg-dark ">
                  <div className="modal-content">
                    <div className="modal-header bg-dark">
                      <h5
                        className="modal-title text-success font-weight-bold"
                        id="exampleModalLabel"
                      >
                        Buy {coinData?.symbol?.toUpperCase()}
                      </h5>
                      <button
                        type="button"
                        className="text-white btn shadow-none"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                    <div className="modal-body bg-dark">
                      <div className="buyform my-4">
                        {balanceData.length > 0 && (
                          <div className="row g-3 align-items-center mb-3">
                            <div className="col-2">
                              <label className="col-form-label">
                                Available
                              </label>
                            </div>
                            <div className="col-8">
                              <input
                                type="number"
                                className="form-control cusbuy-form"
                                disabled
                                placeholder="0.00"
                                value={
                                  balanceData ? balanceData[0]?.balance : 0
                                }
                              />
                            </div>
                            <div className="col-2">
                              <span className="form-tex">USDT</span>
                            </div>
                          </div>
                        )}

                        <div className="row g-3 align-items-center mb-3">
                          <div className="col-2">
                            <label className="col-form-label">Price</label>
                          </div>
                          <div className="col-8">
                            <input
                              type="number"
                              className="form-control cusbuy-form"
                              placeholder="Enter Price"
                              value={coinData?.current_price}
                              disabled
                            />
                          </div>
                          <div className="col-2">
                            <span id="passwordHelpInline" className="form-tex ">
                              {coinData?.symbol?.toUpperCase()}
                            </span>
                          </div>
                        </div>
                        <div className="row g-3 align-items-center mb-3">
                          <div className="col-2">
                            <label className="col-form-label">Amount</label>
                          </div>
                          <div className="col-8">
                            <input
                              type="number"
                              className="form-control cusbuy-form"
                              placeholder="Enter Amount"
                              value={buyAsset.usdtAmount}
                              onChange={(e) =>
                                handleOnChange("usdtAmount", e.target.value)
                              }
                            />
                          </div>
                          <div className="col-2">
                            <span id="passwordHelpInline" className="form-tex ">
                              USDT
                            </span>
                          </div>
                        </div>

                        <div className="row my-4">
                          <div className="col-12 text-center">
                            <div className="percent-div">
                              <button
                                className={`btn persent-btn ${
                                  selectedPercentage === 25 ? "active" : ""
                                }`}
                                onClick={() =>
                                  handlePercentageClick(
                                    25,
                                    balanceData[0]?.balance
                                  )
                                }
                              >
                                25%
                              </button>
                              <button
                                className={`btn persent-btn ${
                                  selectedPercentage === 50 ? "active" : ""
                                }`}
                                onClick={() =>
                                  handlePercentageClick(
                                    50,
                                    balanceData[0]?.balance
                                  )
                                }
                              >
                                50%
                              </button>
                              <button
                                className={`btn persent-btn ${
                                  selectedPercentage === 75 ? "active" : ""
                                }`}
                                onClick={() =>
                                  handlePercentageClick(
                                    75,
                                    balanceData[0]?.balance
                                  )
                                }
                              >
                                75%
                              </button>
                              <button
                                className={`btn persent-btn ${
                                  selectedPercentage === 100 ? "active" : ""
                                }`}
                                onClick={() =>
                                  handlePercentageClick(
                                    100,
                                    balanceData[0]?.balance
                                  )
                                }
                              >
                                100%
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 text-center">
                            {LoginReducer?.isLogged ? (
                              <button
                                className="btn cus-log-btn w-50 shadow-none"
                                onClick={() => handleBuyCoin()}
                              >
                                Buy {coinData?.symbol?.toUpperCase()}
                              </button>
                            ) : (
                              <button
                                className="btn cus-log-btn w-50 shadow-none"
                                data-bs-dismiss="modal"
                                onClick={() => navigate("/login")}
                              >
                                Login / Register
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Sell Modal */}

              <div
                className="modal fade"
                id="exampleModalm"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg bg-dark ">
                  <div className="modal-content">
                    <div className="modal-header bg-dark">
                      <h5
                        className="modal-title text-danger font-weight-bold"
                        id="exampleModalLabel"
                      >
                        Sell {coinData?.symbol?.toUpperCase()}
                      </h5>
                      <button
                        type="button"
                        className="text-white btn shadow-none"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        {" "}
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                    <div className="modal-body bg-dark">
                      <div className="buyform my-4">
                        {sellBalanceData.length > 0 && (
                          <div className="row g-3 align-items-center mb-3">
                            <div className="col-2">
                              <label className="col-form-label">
                                Available
                              </label>
                            </div>
                            <div className="col-8">
                              <input
                                type="number"
                                className="form-control cusbuy-form"
                                disabled
                                placeholder="0.00"
                                value={
                                  sellBalanceData
                                    ? sellBalanceData[0]?.balance
                                    : 0
                                }
                              />
                            </div>
                            <div className="col-2">
                              <span className="form-tex ">
                                {coinData?.symbol?.toUpperCase()}
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="row g-3 align-items-center mb-3">
                          <div className="col-2">
                            <label className="col-form-label">Amount</label>
                          </div>
                          <div className="col-8">
                            <input
                              type="number"
                              className="form-control cusbuy-form"
                              placeholder="Enter Amount"
                              value={sellAsset.sellAmount}
                              onChange={(e) =>
                                handleOnChangeSell("sellAmount", e.target.value)
                              }
                            />
                          </div>
                          <div className="col-2">
                            <span id="passwordHelpInline" className="form-tex ">
                              {coinData?.symbol?.toUpperCase()}
                            </span>
                          </div>
                        </div>
                        {/* <div className="row g-3 align-items-center mb-3">
                          <div className="col-2">
                            <label className="col-form-label">TRX(%)</label>
                          </div>
                          <div className="col-8">
                            <input
                              type="number"
                              className="form-control cusbuy-form"
                              placeholder="6%"
                            />
                          </div>
                          <div className="col-2">
                            <span id="passwordHelpInline" className="form-tex ">
                              USDT
                            </span>
                          </div>
                        </div> */}
                        <div className="row my-4">
                          <div className="col-12 text-center">
                            <div className="percent-div">
                              <button
                                className={`btn persent-btn ${
                                  selectedSellPercentage === 25 ? "active" : ""
                                }`}
                                onClick={() =>
                                  handleSellPercentageClick(
                                    25,

                                    sellBalanceData[0]?.balance
                                  )
                                }
                              >
                                25%
                              </button>
                              <button
                                className={`btn persent-btn ${
                                  selectedSellPercentage === 50 ? "active" : ""
                                }`}
                                onClick={() =>
                                  handleSellPercentageClick(
                                    50,
                                    sellBalanceData[0]?.balance
                                  )
                                }
                              >
                                50%
                              </button>
                              <button
                                className={`btn persent-btn ${
                                  selectedSellPercentage === 75 ? "active" : ""
                                }`}
                                onClick={() =>
                                  handleSellPercentageClick(
                                    75,
                                    sellBalanceData[0]?.balance
                                  )
                                }
                              >
                                75%
                              </button>
                              <button
                                className={`btn persent-btn ${
                                  selectedSellPercentage === 100 ? "active" : ""
                                }`}
                                onClick={() =>
                                  handleSellPercentageClick(
                                    100,
                                    sellBalanceData[0]?.balance
                                  )
                                }
                              >
                                100%
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 text-center">
                            {LoginReducer?.isLogged ? (
                              <button
                                className="btn cus-log-btn w-50 shadow-none"
                                onClick={() => handleSellCoin()}
                              >
                                Sell {coinData?.symbol?.toUpperCase()}
                              </button>
                            ) : (
                              <button
                                className="btn cus-log-btn w-50 shadow-none"
                                data-bs-dismiss="modal"
                                onClick={() => navigate("/login")}
                              >
                                Login/Register
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* buy history */}

            {/* <div className="col-12 col-md-6">
              <div className="card history-card">
                <div className="card-header">
                  <p className="text-success fw-bold">Buy History</p>
                </div>
                <div className="card-body history-card-body">
                  <div className="history-tbl table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
               
                          <th scope="col">Pair</th>
                          <th scope="col">Rate</th>
                          <th scope="col">CoinName</th>
                        </tr>
                        <tr></tr>
                      </thead>
                      {LoginReducer?.isLogged && (
                        <tbody>
                          {buyHistory.map((value, key) => {
                            return (
                              <tr>
                           
                                <td className="text-success">
                                  {value.cryptoPair}
                                </td>
                                <td className="text-success">
                                  {value.rateOfCrypto}
                                </td>
                                <td className="text-success">
                                  {value.targetCoinName}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div> */}
            {/* sell history */}

            {/* <div className="col-12 col-md-6">
              <div className="card history-card">
                <div className="card-header">
                  <p className="text-danger fw-bold">Sell History</p>
                </div>
                <div className="card-body history-card-body">
                  <div className="history-tbl table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                      
                          <th scope="col">Pair</th>
                          <th scope="col">Rate</th>
                          <th scope="col">CoinName</th>
                        </tr>
                      </thead>
                      {LoginReducer?.isLogged && (
                        <tbody>
                          {sellHistory.map((value, key) => {
                            return (
                              <tr>
                             
                                <td className="text-danger">
                                  {value.cryptoPair}
                                </td>
                                <td className="text-danger">
                                  {value.rateOfCrypto}
                                </td>
                                <td className="text-danger">
                                  {value.targetCoinName}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div> */}
          </div>

          {/* trade history */}

          <div className="row my-5">
            <div className="col-12 col-md-12">
              <div className="card-header data-header">
                <p>Trade History</p>
              </div>
              <div className="card data-card p-2">
                {/* data */}

                <div className="row">
                  <div className="col-12">
                    {tradeHistoryData &&
                      tradeHistoryData.map((value, key) => {
                        return (
                          <>
                            <div className="card py-3 within-data">
                              <table className="table table-borderless table-data">
                                <tbody>
                                  {/* <tr>
                                    <th>UserId</th>
                                    <td>:</td>
                                    <td>{value.userId}</td>
                                  </tr> */}
                                  <tr>
                                    <th>Amount(Buy Sell)</th>
                                    <td>:</td>
                                    <td
                                      className={
                                        value.type == 1
                                          ? "text-danger"
                                          : "text-success"
                                      }
                                    >
                                      {value.amount}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Pair</th>
                                    <td>:</td>
                                    <td>{value.cryptoPair}</td>
                                  </tr>
                                  <tr>
                                    <th>Rate </th>
                                    <td>:</td>
                                    <td>{value.rateOfCrypto}</td>
                                  </tr>
                                  <tr>
                                    <th>TotalAmount</th>
                                    <td>:</td>
                                    <td>{value.totalAllotedAmount}</td>
                                  </tr>
                                  <tr>
                                    <th>Fees</th>
                                    <td>:</td>
                                    <td>{value.fees}</td>
                                  </tr>
                                  <tr>
                                    <th>CoinId </th>
                                    <td>:</td>
                                    <td>{value.coinId}</td>
                                  </tr>
                                  <tr>
                                    <th>TargetCoinName</th>
                                    <td>:</td>
                                    <td>{value.targetCoinName}</td>
                                  </tr>
                                  <tr>
                                    <th>Type </th>
                                    <td>:</td>
                                    <td
                                      className={
                                        value.type == 1
                                          ? "text-danger"
                                          : "text-success"
                                      }
                                    >
                                      {value.type == 1 ? "Sell" : "Buy"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Status</th>
                                    <td>:</td>
                                    <td
                                      className={
                                        value.tradeStatus === "Completed"
                                          ? "text-success"
                                          : "text-danger"
                                      }
                                    >
                                      {value.tradeStatus}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
